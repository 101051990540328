// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import React from 'react'
import { Layout } from '../components/_layout'
import { Contacto } from '../components/Contacto'
import { graphql } from 'gatsby'
import { Certificaciones } from '../components/Nosotros'
import { Productos } from '../components/Productos'


export default function ProductosPage( { data } ) {
  const productos = data.productosData.edges
  const certificaciones = data.certificacionesData.edges[0].node

  return (
      <Layout withNav seoTitle="Productos" seoDescription="Productos Neminatura">
        <Productos { ...{ productos } } />
        <Certificaciones { ...{ certificaciones } } />
        <Contacto/>
      </Layout>
  )
}

export const ProductosQuery = graphql`
    query ProductosQuery {
        productosData: allContentfulProducto {
            edges {
                node {
                    id
                    titulo
                    descripcion {
                        descripcion
                    }
                    imagen {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                    fichaTecnica {
                        file {
                            url
                        }
                    }
                }
            }
        }

        certificacionesData: allContentfulNosotrosCertificaciones {
            edges {
                node {
                    id
                    descripcion {
                        descripcion
                    }
                    imagenes {
                        gatsbyImageData(
                            layout: FULL_WIDTH,
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`


