// noinspection JSUnresolvedVariable

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from '../../_common'

import useStyles from './styles'


const ProductList = ( { productos } ) => {
  const styles = useStyles()
  return (
      <Grid container className={ styles.productsList }>
        {
          productos.map( ( { node }, i ) => (
              <Grid key={ `${ node.titulo }-${ i }` } container className={ styles.productItem }>
                <Grid item>
                  <Typography component="span">{ node.titulo }</Typography>
                </Grid>

                <Grid item container className={ styles.productContent }>
                  <Grid item container md={ 6 } className={ styles.imageContainer }>
                    <GatsbyImage
                        alt={ node.titulo }
                        image={ node.imagen.gatsbyImageData }
                        className={ styles.productImage }/>
                  </Grid>

                  <Grid item md={ 6 } container direction={ 'column' }>
                    <Grid item className={ styles.description }>
                      <Typography component="p">{ node.descripcion.descripcion }</Typography>
                    </Grid>

                    {
                      node.fichaTecnica && <Grid item className={ styles.btnContainer }>
                        <Button
                            small
                            aria="Descargar ficha técnica"
                            handleClick={ () =>
                      typeof window !== 'undefined' &&
                      window.open( `https://${ node.fichaTecnica.file.url }` )
                            }
                        >
                          Descargar ficha técnica
                        </Button>
                      </Grid>
                    }

                  </Grid>

                </Grid>
              </Grid>
          ) )
        }
      </Grid>
  )
}

export default ProductList