// noinspection DuplicatedCode,JSUnresolvedVariable

import React from 'react'
import { Grid } from '@material-ui/core'

import { SectionContainer, SectionText, SectionTitle } from '../../_layout'
import ProductList from './ProductList'


const text = 'Estricto apego a normas oficiales, generando un producto de calidad.'

const Productos = ( { productos } ) => {
  return (
      <SectionContainer sectionID={ 'productos' }>
        <Grid style={ { height: 64, width: '100%' } }/>
        <SectionTitle title="Nuestros productos"/>
        <SectionText { ...{ text } } />
        <ProductList { ...{ productos } } />
      </SectionContainer>
  )
}

export default Productos