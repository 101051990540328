// noinspection DuplicatedCode

import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../../_layout/Layout/Layout'


export default makeStyles( ( theme ) => ({
  productsList: {},

  productItem: {
    margin: theme.spacing( 8, 0 ),
    flexDirection: 'column',
    '& span': {
      maxWidth: '100%',
      margin: theme.spacing( 2, 0 ),
      // fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold
    }
  },

  productImage: {
    margin: theme.spacing( 2, 0 ),
    width: '100%',
    flexBasis: 0,
    flexGrow: 1,
    borderRadius: theme.roundnessSmall,
    [theme.breakpoints.up( 'sm' )]: {
      margin: theme.spacing( 4 ),
      maxWidth: '75%',
      minHeight: 300,
      flexBasis: 0,
      flexGrow: 1
    }
  },

  productContent: {
    [theme.breakpoints.up( 'sm' )]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },

  description: {
    margin: theme.spacing( 2, 0 ),
    [theme.breakpoints.up( 'sm' )]: {
      margin: theme.spacing( 0 ),
      padding: theme.spacing( 4 ),
      maxWidth: '60%',
    }
  },


  btnContainer: {
    padding: theme.spacing(4)
  },

  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}) )
